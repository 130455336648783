<template>
    <vx-card :title="'Edit Stock Transfer Order Processing'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="sto_plan_code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Picking List Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="picking_code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedDestWarehouse" :readonly="true" style="width: auto;"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Est. Delivery Date (ETD)</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.reqDeliveryDate)" :readonly="true" style="width: auto;"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Est. Time Arrived (ETA)</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.eta)" :readonly="true" style="width: auto;"></vs-input>
            </div>
        </div>

        <!-- layout processing -->
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table class="table table-bordered" style="width:100%">
                    <thead class="vs-table--thead">
                        <tr>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">SKU</th>
                            <th colspan="5" style="vertical-align: middle; text-align:center;">Suggestion</th>
                            <th colspan="6" style="vertical-align: middle; text-align:center;">Actual</th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle; text-align:center;">HU</th>
                            <th style="vertical-align: middle; text-align:center;">Qty</th>
                            <th style="vertical-align: middle; text-align:center;">Storage Area</th>
                            <th style="vertical-align: middle; text-align:center;">Batch Number</th>
                            <th style="vertical-align: middle; text-align:center;">Expired Date</th>
                            <th style="vertical-align: middle; text-align:center; width:7%;">HU</th>
                            <th style="vertical-align: middle; text-align:center; width:5%;">Qty</th>
                            <th style="vertical-align: middle; text-align:center; width:12%;">Storage Area</th>
                            <th style="vertical-align: middle; text-align:center; width:8%;">Batch Number #</th>
                            <th style="vertical-align: middle; text-align:center;">Expired Date</th>
                            <th style="vertical-align: middle; text-align:center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Layout sto-do -->
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLine.selected_sku.label }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLine.selected_sku.units }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLine.qty }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLines[index].suggestion_storage_area }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLines[index].batch_number }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="skuLines[index].actual_lines.length">
                                    {{ formatDate(skuLines[index].date_expired) }}
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select :filterable="false" :options="skuLine.option_hu"
                                        v-model="skuLines[index].actual_lines[0].selected_hu"
                                        @input="(val) => { onChangeHu(val, index, 0); }" class="w-full" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="number" 
                                        v-model="skuLines[index].actual_lines[0].qty"
                                        @input="(val) => { onChangeQty(val, index, 0); }"
                                        class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select :filterable="false" :options="skuLine.option_warehouses"
                                        label="StorageCode" v-model="skuLines[index].actual_lines[0].storage_area"
                                        @input="(val) => { onChangeStorage(val, index, 0); }" class="w-full" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select :filterable="false" :options="skuLine.option_warehouses" label="Batch"
                                        v-model="skuLines[index].actual_lines[0].batch_number"
                                        @input="(val) => { onChangeBatch(val, index, 0); }" class="w-full" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select :filterable="false" :options="skuLine.option_warehouses"
                                        label="ExpiredDate" v-model="skuLines[index].actual_lines[0].expired_date"
                                        @input="(val) => { onChangeExpDate(val, index, 0); }" class="w-full">
                                        <template slot="option" slot-scope="option">
                                            {{ formatDate(option.ExpiredDate) }}
                                        </template>
                                    </v-select>
                                </td>
                                <td class="td vs-table--td">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button @click.stop="addRow(index)" size="small" color="success"
                                            icon-pack="feather" icon="icon-plus" title="Add Row" />
                                    </div>
                                </td>
                            </tr>

                            <!-- Actual Lines -->
                            <template v-for="(aLine, idxALine) in skuLines[index].actual_lines">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idxALine" v-if="idxALine > 0">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select :filterable="false" :options="skuLine.option_hu"
                                            v-model="skuLines[index].actual_lines[idxALine].selected_hu"
                                            @input="(val) => { onChangeHu(val, index, idxALine); }" class="w-full" />
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input 
                                            type="number" 
                                            v-model="skuLines[index].actual_lines[idxALine].qty"
                                            @input="(val) => { onChangeQty(val, index, idxALine); }"
                                            class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select :filterable="false" :options="skuLine.option_warehouses"
                                            label="StorageCode"
                                            v-model="skuLines[index].actual_lines[idxALine].storage_area"
                                            @input="(val) => { onChangeStorage(val, index, idxALine); }"
                                            class="w-full" />
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select :filterable="false" :options="skuLine.option_warehouses" label="Batch"
                                            v-model="skuLines[index].actual_lines[idxALine].batch_number"
                                            @input="(val) => { onChangeBatch(val, index, idxALine); }" class="w-full" />
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <v-select :filterable="false" :options="skuLine.option_warehouses"
                                            label="ExpiredDate"
                                            v-model="skuLines[index].actual_lines[idxALine].expired_date"
                                            @input="(val) => { onChangeExpDate(val, index, idxALine); }"
                                            class="w-full" />
                                    </td>
                                    <td class="td vs-table--td">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="skuLines[index].actual_lines.length > 1"
                                                @click.stop="removeRow(index, idxALine)" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row" />
                                            <vs-button @click.stop="addRow(index)" size="small" color="success"
                                                v-if="skuLines[index].actual_lines.length == 1" icon-pack="feather"
                                                icon="icon-plus" title="Add Row" />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Swal from 'sweetalert2'
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default {
    components: {
        vSelect,
        flatPickr,
    },
    data() {
        return {
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                plugins: [new ConfirmDatePlugin()],
            },
            id: null,
            readonly: true,
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            dataLines: [],
            dataLinesActual: [],
            skuLines: [
                {
                    stock_relocation_plan_line_id: 0,
                    selected_sku: null,
                    option_warehouses: [],
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            storage_area: null,
                            batch_number: "",
                            expired_date: "",
                        }
                    ],
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-processing/get/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.dataLines = resp.data.picking_lines;
                        this.sto_plan_code = resp.data.headers.sto_plan_code;
                        this.picking_code = resp.data.headers.picking_code;
                        this.selectedDestWarehouse = resp.data.headers.dest_warehouse_code + " - " + resp.data.headers.dest_warehouse_name;
                        this.reqDeliveryDate = resp.data.headers.req_delivery_date;
                        this.eta = resp.data.headers.eta;
                        this.skuLines = resp.data.sku_lines
                        this.skuLines.forEach((val) => {
                            val.actual_lines.forEach((valIdx) => {
                                valIdx.expired_date = this.formatDate(valIdx.expired_date)
                            })
                        })
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setDefault() {
            this.id = null
            this.sto_plan_code = null
            this.picking_code = null
            this.selectedSrcWarehouse = null
            this.selectedDestWarehouse = null
            this.reqDeliveryDate = null
            this.eta = null
            this.skuLines = [
                {
                    stock_relocation_plan_line_id: 0,
                    selected_sku: null,
                    option_warehouses: [],
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            storage_area: null,
                            batch_number: "",
                            expired_date: "",
                        }
                    ],
                }
            ]
            this.file = null
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing",
            });
        },
        handleSubmit() {
            let skus = [];
            let message = "";

            this.skuLines.forEach((skul) => {
                skul.actual_lines.forEach((val) => {
                    if (val.selected_hu == null) {
                        message = "please check hu, hu is required";
                        return false;
                    }

                    if (val.qty < 1) {
                        message = "qty is required more than zero";
                        return false;
                    }

                    if (val.storage_area == null) {
                        message = "please select storage area";
                        return false;
                    }

                    if (val.batch_number == null) {
                        message = "please select batch number";
                        return false;
                    }

                    if (val.expired_date == null) {
                        message = "please select expired date";
                        return false;
                    }
                    val.item_stock_id = val.item_stock_id == null ? 0 : val.item_stock_id,
                    val.qty = parseInt(val.qty),
                    val.srpi_id = skul.srpi_id;
                    val.selected_sku = skul.selected_sku;

                    skus.push(val);
                });
            });

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("skus", JSON.stringify(skus))

            this.$vs.notify({
                color: "warning",
                title: "Checking..",
                text: "Checking stock in progress",
                position: "top-center",
                iconPack: "feather",
                icon: "icon-x-circle",
            });
            this.$vs.loading();
            this.$http
                .post("/api/wms/v1/outbound/sto-processing/check-stock/" + this.id, formData)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'All stock available!',
                            text: "Are you sure want to update picking this picking document?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Update'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$vs.loading();
                                this.$http
                                    .put("/api/wms/v1/outbound/sto-processing/" + this.id, formData)
                                    .then(resp => {
                                        if (resp.code == 200) {
                                            this.$vs.loading.close();
                                            Swal.fire(
                                                'Done!',
                                                'Picking Document has been updated.',
                                                'success'
                                            )
                                            this.draw++;
                                            this.setDefault();
                                            this.handleBack();
                                        } else {
                                            this.$vs.loading.close();
                                            Swal.fire({
                                                title: 'Something gone wrong',
                                                text: resp.message,
                                                icon: 'info',
                                            });
                                        }
                                    });
                            }
                        });
                    } else {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'Stock is less than required!',
                            text: resp.message,
                            icon: 'error',
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        onChangeHu(val, index, idxALine) {
            this.skuLines[index].actual_lines[idxALine].selected_hu = val.label
            this.skuLines[index].actual_lines[idxALine].qty = 0
            this.skuLines[index].actual_lines[idxALine].storage_area = null
            this.skuLines[index].actual_lines[idxALine].batch_number = null
            this.skuLines[index].actual_lines[idxALine].expired_date = null
        },
        onChangeStorage(val, index, idxALine) {
            this.skuLines[index].actual_lines[idxALine].storage_area = val.StorageCode
            this.skuLines[index].actual_lines[idxALine].option_warehouses = val.Batch

            // set batch number & exp date
            this.skuLines[index].actual_lines[idxALine].batch_number = val.Batch
            this.skuLines[index].actual_lines[idxALine].expired_date = this.formatDate(val.ExpiredDate);
        },
        onChangeBatch(val, index, idxALine) {
            this.skuLines[index].actual_lines[idxALine].batch_number = val
        },
        onChangeExpDate(val, index, idxALine) {
            this.skuLines[index].actual_lines[idxALine].expired_date = this.formatDate(val.ExpiredDate)
        },
        onChangeQty(val, index, idxALine) {
            this.skuLines[index].actual_lines[idxALine].qty = val
            if (this.skuLines[index].actual_lines[idxALine].qty < 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Qty must be greater than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check-circle",
                });

                this.skuLines[index].actual_lines[idxALine].qty = 0
            }
        },
        removeRow(index, indexAcLine) {
            this.skuLines[index].actual_lines.splice(indexAcLine, 1);
        },
        addRow(index) {
            this.skuLines[index].actual_lines.push({
                "item_stock_id": 0,
                "selected_hu": null,
                "qty": 0,
                "storage_area": null,
                "batch_number": "",
                "expired_date": "",
            });
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        formatDate_2: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>